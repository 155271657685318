import React, { useEffect, useState } from "react"
import {
    LinkingOptions,
    NavigationContainer, DarkTheme as NavigationDarkTheme,
    DefaultTheme as NavigationDefaultTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { PaperProvider } from "react-native-paper";
import { MD3DarkTheme, MD3LightTheme, adaptNavigationTheme } from 'react-native-paper';
import merge from 'deepmerge';
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RootStackParamList } from "./src/RootStackParamList";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';
import * as Device from 'expo-device';
import { Platform } from "react-native";
import { MainTab, DataDeletionTab, PrivacyTab } from "./src";

const { LightTheme, DarkTheme } = adaptNavigationTheme({
    reactNavigationLight: NavigationDefaultTheme,
    reactNavigationDark: NavigationDarkTheme,
});
const CombinedDefaultTheme = merge(MD3LightTheme, LightTheme);
const CombinedDarkTheme = merge(MD3DarkTheme, DarkTheme);

const TopTab = createMaterialTopTabNavigator();
const Tab = createBottomTabNavigator();
class UAModel {
    model?: string | null;
    platform?: string | null;
    platformVersion?: string | null;
}
export const Router = () => {
    const linking: LinkingOptions<RootStackParamList> = {
        prefixes: ['https://wishy.waep.dk/', 'http://localhost:19006/'],
        config: {
            initialRouteName: "Home",
            screens: {
                Home: "Home",
                Data: "Data",
                Privacy: "Privacy"
            },
        }
    };
    const [ua, setUa] = useState<any>();
    const initialRoute = location.pathname.replace("/", "");
    useEffect(() => {
        getDeviceData();
    }, [0])
    const getDeviceData = async () => {
        var agentData = (navigator as any).userAgentData;
        try {
            var ua = await agentData.getHighEntropyValues(["model", "platform", "platformVersion", "uaFullVersion"]);
            if (!ua || !ua.model || !ua.platformVersion) {
                var uModel = new UAModel();
                uModel.model = Device.modelName;
                uModel.platform = Device.osName;
                uModel.platformVersion = Device.osVersion;
                setUa(uModel);
            } else {
                setUa(ua);
            }
        } catch (error) {
            if (!ua || !ua.model || !ua.platformVersion) {
                var uModel = new UAModel();
                uModel.model = Device.modelName;
                uModel.platform = Device.osName;
                uModel.platformVersion = Device.osVersion;
                setUa(uModel);
            }
        }
    }

    return (
        <PaperProvider theme={CombinedDarkTheme}>
            <SafeAreaProvider>
                <NavigationContainer theme={CombinedDarkTheme} linking={linking}>
                    {Platform.OS == "android" || Platform.OS == "ios" || ua?.platform == "Android" || ua?.platform == "iOS" || ua?.platform == "Ios" ?
                        <BottomTabs initialRoute={initialRoute} />
                        : <TopTabs initialRoute={initialRoute} />}
                    {/* <TopTabs initialRoute={initialRoute} /> */}
                </NavigationContainer>
            </SafeAreaProvider>
        </PaperProvider>
    )
}
const BottomTabs = ({ initialRoute }: { initialRoute: string }) => {
    return (
        <Tab.Navigator initialRouteName={initialRoute}>
            <Tab.Screen name="Home" component={MainTab} options={{
                title: "Hjem",
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="home" size={26} color={color} />
                )
            }} />
            <Tab.Screen name="Data" component={DataDeletionTab} options={{
                title: "Dine data",
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="account" size={26} color={color} />
                )
            }} />
            <Tab.Screen name="Privacy" component={PrivacyTab} options={{
                title: "Privatlivspolitik",
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="note-text" size={26} color={color} />
                )
            }} />
        </Tab.Navigator>
    )
}
const TopTabs = ({ initialRoute }: { initialRoute: string }) => {
    return (
        <TopTab.Navigator initialRouteName={initialRoute}>
            <Tab.Screen name="Home" component={MainTab} options={{
                title: "Hjem",
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="home" size={26} color={color} />
                )
            }} />
            <Tab.Screen name="Data" component={DataDeletionTab} options={{
                title: "Dine data",
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="account" size={26} color={color} />
                )
            }} />
            <Tab.Screen name="Privacy" component={PrivacyTab} options={{
                title: "Privatlivspolitik",
                tabBarIcon: ({ color }) => (
                    <MaterialCommunityIcons name="note-text" size={26} color={color} />
                )
            }} />
        </TopTab.Navigator>
    )
}