import { Text, TextInput, Button } from "react-native-paper"
import { MainWrapper, ThemedView } from "../components"
import { useState } from "react"

export const DataDeletionTab = () => {
    const [mail, setMail] = useState<string>("");
    return (
        <MainWrapper>
            <ThemedView style={{ padding: 20, flex: 1, flexDirection: "column", alignItems: "center" }}>
                <Text style={{ fontSize: 20, marginBottom: 10 }}>Her kan du bede om at få slettet dine data i Wishy</Text>
                <Text style={{ fontSize: 20, marginBottom: 10 }}>Du skal blot indtaste din email og trykke "slet mine data", herefter håndterer systemet det selv i løbet af maks 30 dage</Text>
                <br />
                <Text style={{ fontSize: 20, marginBottom: 10 }}>Din email</Text>
                <TextInput value={mail} onChangeText={(e) => { setMail(e) }} placeholder="Email" />
                <br />
                <Button mode="contained" onPress={() => { }}>Slet mine data</Button>
            </ThemedView>
        </MainWrapper>
    )
}