import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ThemedView } from "./ThemedView";

export const MainWrapper = ({ children }: React.PropsWithChildren) => {
    const insets = useSafeAreaInsets();

    return (
        <ThemedView style={{
            paddingBottom: insets.bottom,
            paddingLeft: insets.left,
            paddingRight: insets.right,
            flex: 1,
        }}>
            {children}
        </ThemedView>
    )
}