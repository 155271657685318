import { useEffect } from "react";
import { MainWrapper, ThemedView } from "../components"
import { Button, Divider, Text } from 'react-native-paper';
import { useNavigation } from "@react-navigation/native";
import { MaterialTopTabNavigationProp } from "@react-navigation/material-top-tabs";
import { RootStackParamList } from "../RootStackParamList";

export const MainTab = () => {
    const navigation = useNavigation<MaterialTopTabNavigationProp<RootStackParamList>>();
    return (
        <MainWrapper>
            <ThemedView style={{ padding: 20, flex: 1, flexDirection: "column", alignItems: "center" }}>
                <Text style={{fontSize: 30}}>Velkommen til Wishy</Text>
                <br />
                <Text style={{fontSize: 20}}>
                    Her kan du anmode om at få slettet dine data fra Wishy, samt læse vores Privatlivspolitik
                </Text>
                <br />
                <Button onPress={() => {navigation.navigate("Data")}} labelStyle={{fontSize: 20}}>Gå til data</Button>
                <br />
                <Button onPress={() => {navigation.navigate("Privacy")}} labelStyle={{fontSize: 20}}>Gå til Privatlivspolitik</Button>
            </ThemedView>
        </MainWrapper>
    )
}